import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { InputRefNoDialog } from '@app/home';
import { Case } from '@app/_models/case';
import { DatumNote } from '@app/_models/datumNote';
import { Email } from '@app/_models/email';
import { EmailRecord } from '@app/_models/email-record';
import { InternalTrade } from '@app/_models/internal-trade';
import { JobHistory } from '@app/_models/job-history';
import { JobStatus } from '@app/_models/job-status';
import { AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';
import { MsalService } from '@azure/msal-angular';
import { first } from 'rxjs';
import Swal from 'sweetalert2';
import { Mk3Instructions, NIBInstructions, NIBQuoteRequest, RRInstructions, RRQuoteRequest } from '@app/_helpers/constants';

@Component({
  selector: 'app-internal-trade-details',
  templateUrl: './internal-trade-details.component.html',
  styleUrls: ['./internal-trade-details.component.scss'],
})
export class InternalTradeDetailsComponent implements OnInit {
  isLoading = true;
  searchValue = '';
  instructionType:string[]=[];
  internalTrade!: InternalTrade;
  dataSourceForEmailRecords!: MatTableDataSource<any>;
  dataSourceEmailRecords!: MatTableDataSource<any>;
  displayedEmailRecordsColumns: string[] = [
    'refNo',
    'instructionTypeId',
    'attachmentName',
    'startDate',
    'endDate',
    'dateSent',
    'sentBy',
    'jobCost',
    'statusId',
    'details',
    'viewDocument',
    'retract'
  ];

  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  inputDialogRef: any;
  jobStatusesArray: Array<JobStatus> = [];

  constructor(
    public restService: RestService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    public functionsService: FunctionsService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public appComponent: AppComponent,
    private router: Router,
    private msalService: MsalService
  ) {}

  ngOnInit(): void {
    this.instructionType[1] = NIBInstructions;
    this.instructionType[2] = NIBQuoteRequest;
    this.instructionType[3] = RRInstructions;
    this.instructionType[4] = RRQuoteRequest;
    this.instructionType[5] = Mk3Instructions;
    this.restService
      .getInternalTrade(this.route.snapshot.params['internalTradeId'])
      .subscribe((data) => {
        this.internalTrade = { ...data[0] };
        this.getEmailRecords();

        this.restService
          .getJobStatuses()
          .subscribe((jobStatuses: JobStatus[]) => {
            this.jobStatusesArray = jobStatuses;
          });
      });
  }

  getEmailRecords() {
    this.isLoading = true;
    return this.restService
      .getEmailRecordsByInternalTradeId(
        this.route.snapshot.params.internalTradeId
      )
      .subscribe(
        (emailRecords: any) => {
          this.dataSourceEmailRecords = new MatTableDataSource(emailRecords);
          this.dataSourceEmailRecords.filteredData.forEach(item => {
            item.jobStatus = this.getJobStatus(item.statusId);
            item.instructionType = this.instructionType[item.instructionTypeId];
          });
          this.dataSourceForEmailRecords = this.dataSourceEmailRecords;
          this.dataSourceEmailRecords.filter = this.searchValue ? this.searchValue : '';
          this.dataSourceEmailRecords.sort = this.matSort;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          //this.alertService.error(error);
          Swal.fire(
            'Error (Internal Trades): getEmailRecordsByInternalTradeId. Please contact the IT Department with details. Error: ' + error,
            '',
            'error'
          );
        }
      );
  }

  openInstructionPDF(emailRecordId: number) {
    this.restService.getEmailRecord(emailRecordId).subscribe(
      (data: EmailRecord) => {
        this.functionsService.openPdf(data.pdfData);
      },
      (error) => {
        //this.alertService.error(error);
        Swal.fire(
          'Error (Internal Trades): getDocument. Please contact the IT Department with details. Error: ' + error,
          '',
          'error'
        );
      }
    );
  }

  downloadPdf(base64String: any, fileName: string) {
    const source = base64String;
    const link = document.createElement('a');
    link.href = source;
    link.setAttribute('target', '_blank');
    link.download = fileName;
    link.click();
  }

  newWorkInstructions() {
    this.openInputRefNoDialog();
  }

  openInputRefNoDialog() {
    this.inputDialogRef = this.dialog.open(InputRefNoDialog, {
      minWidth: '400px',
      maxWidth: '1000px',
      data: {
        internalTrade: this.internalTrade,
        userName: this.appComponent.getUserName(),
        userEmail: this.appComponent.getUserEmail(),
      },
    });
  }

  jobDetails(jobId: number) {
    this.router.navigate(['../job-details/' + jobId], {
      queryParams: { internalTrade: true },
    });
  }

  async retract(emailRecordId: number) {
    this.alertService.clear();
    let title =
      'An email will be sent to <b><u>' +
      this.internalTrade.displayName +
      '</b></u> to disregard this instruction.\r\n<b><u><p style="color:red">This cannot be reversed if you proceed!!!</p></b></u>Are you sure you want to Retract this?';
    await Swal.fire({
      title: title,
      text: 'Please provide the reason for cancellation',
      input: 'text',
      inputPlaceholder: 'Please enter the reason here',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      confirmButtonColor: '#007bff',
      denyButtonColor: '#dc3545',
      focusDeny: true,
      width: 450,
      padding: '1em',
      heightAuto: false,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!';
        } else if (value.length > 250) {
          return 'Reason too Long!. Should be less than 250 characters';
        } else {
          return '';
        }
      },
    }).then(async (result) => {
      let retractReason = result.value;
      if (result.isConfirmed) {
        let emailRecord = new EmailRecord();
        emailRecord.id = emailRecordId;
        emailRecord.retracted = true;
        emailRecord.retractedBy =
          this.msalService.instance.getActiveAccount()!.name!;
        emailRecord.retractedOn = new Date();
        emailRecord.statusId = 2;

        this.restService
          .updateEmailRecord(emailRecordId, 2, emailRecord)
          .pipe(first())
          .subscribe({
            next: () => {
              try {
                this.restService
                  .getEmailRecord(emailRecordId)
                  .subscribe((emailRecord: EmailRecord) => {
                    var email: Email;
                    let riskAddress = '';

                    this.restService
                      .getCase(emailRecord.refNo)
                      .subscribe((caseDetails: Case) => {
                        riskAddress = (
                          this.functionsService.blankIfNull(
                            caseDetails.situationStreet
                          ) +
                          ' ' +
                          this.functionsService.blankIfNull(
                            caseDetails.situationSuburb
                          ) +
                          ' ' +
                          this.functionsService.blankIfNull(caseDetails.state) +
                          ' ' +
                          this.functionsService.blankIfNull(
                            caseDetails.postCode
                          )
                        )?.trim();

                        let tradeNamesGreeting = '';
                        let additionalInternalTradeIDs: number[];
                        if (emailRecord.additionalInternalTrades != null) {
                          additionalInternalTradeIDs =
                            emailRecord.additionalInternalTrades
                              .split(',')
                              .map(Number);

                          this.restService
                            .getInternalTrades()
                            .subscribe(
                              (internalTradesArray: Array<InternalTrade>) => {
                                if (internalTradesArray.length != 0) {
                                  additionalInternalTradeIDs.forEach(
                                    (item: number) => {
                                      tradeNamesGreeting =
                                        tradeNamesGreeting +
                                        ', ' +
                                        internalTradesArray.find(
                                          (trade) => item == trade.employeeId
                                        )?.displayName;
                                    }
                                  );
                                }
                              }
                            );
                        }

                        tradeNamesGreeting =
                          'Hello ' +
                          this.internalTrade.displayName +
                          tradeNamesGreeting;

                        let body =
                          tradeNamesGreeting +
                          ',<br/>Please cancel the previous email instructions sent on ' +
                          this.datePipe.transform(
                            emailRecord.dateSent,
                            'dd/MM/yyyy'
                          ) +
                          ' with the subject <u>' +
                          emailRecord.subject +
                          '</u>, for the address ' +
                          riskAddress;
                        email = {
                          from: emailRecord.fromEmail,
                          to: emailRecord.toEmail,
                          cc: emailRecord.ccEmails,
                          bcc: null,
                          subject: 'Please Cancel - ' + emailRecord.subject,
                          body: body,
                          attachmentName: null,
                          attachmentContent: null,
                          extraAttachmentName: null,
                          extraAttachmentContent:null,
                        };

                        this.restService.sendEmail(email, 'Email Type:Internal Trade Retract Instruction'+ ', User Name:' + this.msalService.instance.getActiveAccount()!.name! 
                              + ', Internal Trade:'+this.internalTrade.displayName+', Internal Trade Id:'+this.internalTrade.employeeId.toString()
                             + ', RefNo:' + emailRecord.refNo.toString()+', EmailRecordId:'+ emailRecordId.toString()).subscribe(
                          (response: any) => {
                            if (response.status == 200) {
                              let newNote: DatumNote = {
                                refNo: emailRecord.refNo,
                                date: this.datePipe
                                  .transform(new Date(), 'yyyy-MM-dd')
                                  ?.toString()!,
                                addedBy:
                                  this.msalService.instance.getActiveAccount()!.name!,
                                initiatedBy: 'Mk3 Staff',
                                noteTypeId: 4,
                                subTypeId: 2,
                                reasonId: 14,
                                notes:
                                  'Retracted Instructions to Internal Trade ' +
                                  this.internalTrade.displayName +
                                  ' (ID ' +
                                  this.internalTrade.employeeId +
                                  '). Reason: ' +
                                  retractReason,
                                private: -1,
                                timeStamp: null,
                                followUpDate: null,
                                followUpUser: null,
                                followUpComplete: null,
                                clientId: 1,
                              };

                              this.restService
                                .addNewNoteToDatum(newNote)
                                .pipe(first())
                                .subscribe({
                                  next: () => {
                                    let jobHistory = new JobHistory();
                                    jobHistory.jobId = emailRecordId;
                                    jobHistory.statusId = 2;
                                    jobHistory.comments = retractReason;
                                    jobHistory.date = new Date();
                                    jobHistory.addedBy =
                                      this.msalService.instance.getActiveAccount()!.name!;
                                    jobHistory.timeStamp = null;
                                    this.restService
                                      .addNewJobHistoryRecord(jobHistory)
                                      .subscribe((x) => {
                                        console.log(x);
                                        Swal.fire(
                                          'Instructions Retracted successfully!\r\nEmail has been sent to the Internal Trade',
                                          '',
                                          'success'
                                        ).then(() => {
                                          if (emailRecordId) {
                                            this.router
                                              .navigate([
                                                '../job-details/' +
                                                  emailRecordId,
                                              ])
                                              .then(() => {
                                                window.location.reload();
                                              });
                                          }
                                        });
                                      });
                                  },
                                });
                            } else {
                              // this.alertService.error(
                              //   'Error occured: Status code = ' +
                              //     response.status
                              // );
                                Swal.fire(
                                  'Error (Internal Trades Retract): restService.sendEmail: Status code = ' + response.status,
                                  '',
                                  'error'
                                );
                              this.isLoading = false;
                            }
                          },
                          // (error: any) => {
                          //   console.log(error);
                          // }
                          (err: any) => {
                            Swal.fire(
                              'Error (Internal Trades Retract): restService.sendEmail: ' + err,
                              '',
                              'error'
                            );
                            console.log(
                              'Error (Internal Trades Retract): restService.sendEmail: ' + err
                            );
                          },
                        );
                      });
                  });
              } catch {
                // this.alertService.error(
                //   'Error occured. Please contact the IT Department with details'
                // );
                Swal.fire(
                  'Error (Internal Trades Retract): restService.sendEmail. Please contact the IT Department with details',
                  '',
                  'error'
                );
                this.isLoading = false;
              }
            },
          });
      }
    });
  }

  //Search by keyword
  onSearchKeyUp(search: { value: any }) {
    try {
        this.searchValue =search.value;
        var currentFilter = search.value;
        this.filterInstructions(currentFilter);
     
   } catch (error) {
     Swal.fire('Error in Search by keyword : ' + error, '', 'error');
   }
 }

 filterInstructions(currentFilter: string)
 {
  let result;
  if (currentFilter && currentFilter.length > 0) {
    this.isLoading = true;
    result = this.dataSourceForEmailRecords.filteredData.filter(item => (item.refNo != null && item.refNo.toString().includes(currentFilter)) || 
                                      (item.id != null && item.id.toString().includes(currentFilter)) || 
                                      (item.instructionType != null && item.instructionType.toLowerCase().includes(currentFilter.toLowerCase())) || 
                                      (item.jobStatus != null && item.jobStatus.toLowerCase().includes(currentFilter.toLowerCase())) || 
                                      (item.attachmentName != null && item.attachmentName.toLowerCase().includes(currentFilter.toLowerCase())) || 
                                      (item.sentBy != null && item.sentBy.toLowerCase().includes(currentFilter.toLowerCase()))
                                   );
    this.dataSourceEmailRecords = new MatTableDataSource(result);
    this.isLoading = false;
   
  } else if (currentFilter == null || currentFilter.length == 0) {
    result = this.dataSourceForEmailRecords.filteredData;
    this.dataSourceEmailRecords = new MatTableDataSource(result);
    this.isLoading = false;
  }
 }

  //Clear search by keyword
  onClearClicked(search: { value: string }) {
    this.dataSourceForEmailRecords.filter = '';
    search.value ='';
    this.searchValue ='';
    this.dataSourceEmailRecords = new MatTableDataSource(this.dataSourceForEmailRecords.filteredData);
       this.isLoading = false;
  }

  getJobStatus(statusId: number) {
    return this.jobStatusesArray.find((item) => item.id == statusId)
      ?.description;
  }
}
