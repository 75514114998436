<div id="print" #print style="height:auto">
    <div class="loading-container" *ngIf="isLoading">
        <div class="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <section fxLayout="row wrap" fxLayoutAlign="center center" class="row-height" class="bdrop">
        <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()" fxLayout="column wrap"
            fxLayoutAlign="center center" fxLayoutGap="5px">
            <mat-dialog-content text-align="middle" style="overflow-y: hidden !important; width: 1110px;">
                <mat-card-title>Document Upload</mat-card-title>
                <mat-card fxFlex.xs="100%">
                    <mat-card-content>
                        <div style="align-items: center;padding-left: 30px;">
                            <span style="white-space: nowrap;">
                                <label class="label-custom">Document Type:</label>
                                <select
                                    style="display: inline-block; width: 310px!important;margin-left: 60px;margin-bottom: 5px;margin-top: 5px;"
                                    class="form-control" (change)="onDocumentTypeChange($event)"  formControlName="documentType">
                                    <option value="" disabled selected hidden>Choose an option</option>
                                    <option *ngFor="let item of documentTypes" [value]="item.documentTypeId">{{
                                        item.documentName }}</option>
                                </select>
                                <mat-error *ngIf="hasError('documentType', 'pattern')">Invalid!</mat-error>

                                <label class="label-custom" style="margin-left:50px;" *ngIf="documentTypeId >0">Trading
                                    Name:</label>
                                <input type="text" id="documentTradeName" *ngIf="documentTypeId >0" formControlName="tradingName"
                                    style="display: inline-block;  max-width: 310px!important;  margin-left:114px;margin-bottom: 5px;margin-top: 5px;"
                                    class="form-control" [(ngModel)]="documentTypeDetails.tradeName" [ngClass]="{ 'is-invalid': !documentTypeDetails.tradeName || isDupicateTrade }"
                                    (change)="onchageofTrade()" />
                            </span>
                            <div *ngIf="documentTypeId >0">
                            <span style="white-space: nowrap;">
                                <label class="label-custom">Entry Name:</label>
                                <input type="text" id="documentEntryName"
                                    style="display: inline-block;  max-width: 310px!important;  margin-left:84px;margin-bottom: 5px;margin-top: 5px;"
                                    class="form-control" [(ngModel)]="contractor.companyName" 
                                    [ngModelOptions]="{standalone: true}" (change)="onChange()" />
                                <label class="label-custom" style="margin-left:50px;">Geo Region:</label>
                                    <select [(ngModel)]="documentTypeDetails.geoRegionId" [ngModelOptions]="{standalone: true}"
                                    style="display: inline-block; width: 310px!important;margin-left: 131px;margin-bottom: 5px;margin-top: 5px;"
                                    class="form-control" (change)="onChange()">
                                    <option value="" disabled selected hidden>Choose an option</option>
                                    <option *ngFor="let geoRegion of geoRegions | async" [value]="geoRegion.geoRegionId">{{
                                        geoRegion.geoRegion }}</option>
                                </select>
                            </span>
                            <span style="white-space: nowrap;">
                                <label class="label-custom">Address:</label>
                                    <input type="text" maxlength="255" class="form-control" style="display: inline-block;  max-width: 310px!important;  margin-left:105px;margin-bottom: 5px;margin-top: 5px;"  [(ngModel)]="documentTypeDetails.address" [ngModelOptions]="{standalone: true}" (change)="onChange()"/>
                                    <label class="label-custom" style="margin-left:50px;">Suburb - Postcode - State:</label>
                                    <input type="text"  [(ngModel)]="documentTypeDetails.suburb" maxlength="255" class="form-control" style="display: inline-block;  max-width: 155px!important;  margin-left:43px;margin-bottom: 5px;margin-top: 5px;" [ngModelOptions]="{standalone: true}" (change)="onChange()"/>
                                    <input digitOnly inputmode="numeric" placeholder="0000" type="text" maxlength="4" pattern="[0-9]*" class="form-control" style="display: inline-block;  max-width: 62px!important;margin-left:1px;margin-bottom: 5px;margin-top: 5px;"  [(ngModel)]="documentTypeDetails.postcode" [ngModelOptions]="{standalone: true}" (change)="onChange()"/>
                                  <select class="form-control" [(ngModel)]="documentTypeDetails.stateId" style=" display: inline-block;margin-left:1px; width:91px" [ngModelOptions]="{standalone: true}" (change)="onChange()">
                                    <option *ngFor="let s of states | async" [ngValue]="s.stateId">
                                      {{ s.state }}
                                    </option>
                                  </select>
                                </span>
                                <span style="white-space: nowrap;">
                                    <label class="label-custom" >Main Contact Email:</label>
                                    <input type="text" id="documentMainContactEmail"
                                        style="display: inline-block;  max-width: 310px!important;  margin-left:35px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.mainContactEmail"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()" />
                                <label class="label-custom"style="margin-left:50px;" >Main Contact Name - Phone:</label>
                                <input type="text" maxlength="255" class="form-control" style="display: inline-block;  max-width: 194px!important;  margin-left:26px;margin-bottom: 5px;margin-top: 5px;"(change)="onChange()"  [(ngModel)]="documentTypeDetails.mainContactName" [ngModelOptions]="{standalone: true}"/>
                                    <input type="text" digitOnly maxlength="10" class="form-control" style="display: inline-block;  max-width: 116px!important;  margin-left:1px;"  (change)="onChange()" [(ngModel)]="documentTypeDetails.mainContactPhone" [ngModelOptions]="{standalone: true}"/>
                                </span>
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">Account Contact Email:</label>
                                    <input type="text" id="documentAccountContactEmail"
                                        style="display: inline-block;  max-width: 310px!important;  margin-left:14px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.accountContactEmail"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()" />
                                <label class="label-custom"  style="margin-left:50px;">Account Contact Name - Phone:</label>
                                    <input type="text" maxlength="255" class="form-control" style="display: inline-block;  max-width: 194px!important;  margin-left:5px;margin-bottom: 5px;margin-top: 5px;" (change)="onChange()"  [(ngModel)]="documentTypeDetails.accountContactName"[ngModelOptions]="{standalone: true}"/>
                                    <input type="text" digitOnly maxlength="10" class="form-control" style="display: inline-block;  max-width: 116px!important;  margin-left:1px;" (change)="onChange()" [(ngModel)]="documentTypeDetails.accountContactPhone"[ngModelOptions]="{standalone: true}"/>
                                </span>
                                <span style="white-space: nowrap;">
                                <label class="label-custom">Main Trade Category:</label>
                                    <select class="form-control" [(ngModel)]="contractor.mainTradeCategoryId" style="display: inline-block;  max-width: 310px!important;  margin-left:28px;margin-bottom: 5px;margin-top: 5px;" [ngModelOptions]="{standalone: true}" (change)="onChange()">
                                        <option *ngFor="let tc of tradeClassifications | async" [ngValue]="tc.tradeId">
                                          {{ tc.tradeName }}
                                        </option>
                                      </select>
                                <label class="label-custom" style="margin-left:50px;">Entity Type :</label>
                                <select class="form-control" [(ngModel)]="contractor.entityTypeId" style="display: inline-block;  max-width: 310px!important;  margin-left:130px;margin-bottom: 5px;margin-top: 5px;" [ngModelOptions]="{standalone: true}" (change)="onChange()">
                                    <option *ngFor="let s of entityTypes | async" [ngValue]="s.entityTypeId">
                                      {{ s.entityType }}
                                    </option>
                                  </select>
                                </span>
                                <br>
                                <span style="white-space: nowrap;">
                                    <label class="label-custom" *ngIf="contractor.abn"><a
                                        href="https://abr.business.gov.au/ABN/View?id={{contractor.abn}}"
                                        target="_blank">
                                        ABN </a>
                                    </label>
                                    <label class="label-custom" *ngIf="!contractor.abn"><a
                                        href="https://abr.business.gov.au/Search/ResultsActive?SearchText={{documentTypeDetails.tradeName}}"
                                        target="_blank"> ABN </a></label>
                                <input type="text" placeholder="XX XXX XXX XXX" type="text"  maxlength="14" appAbnMask  formControlName="abn"
                                    style="display: inline-block;  max-width: 310px!important;  margin-left:135px;margin-bottom: 5px;margin-top: 5px;"
                                    class="form-control" [(ngModel)]="contractor.abn"  [ngClass]="{ 'is-invalid': !contractor.abn || contractor.abn.length<14 }"
                                    (change)="onChange()" />
                           
                                <label class="label-custom" style="margin-left:50px;"*ngIf="contractor.acn"><a
                                    href="https://abr.business.gov.au/ABN/View?id={{contractor.acn}}"
                                    target="_blank">
                                    ACN </a>
                                </label>
                                <label class="label-custom" style="margin-left:50px;"*ngIf="!contractor.acn"><a
                                    href="https://abr.business.gov.au/Search/ResultsActive?SearchText={{documentTypeDetails.tradeName}}"
                                    target="_blank"> ACN </a></label>
                                <input type="text" id="documentAcn" maxlength="100"
                                    style="display: inline-block;  max-width: 310px!important; margin-left:175px;margin-bottom: 5px;margin-top: 5px;"
                                    class="form-control" [(ngModel)]="contractor.acn"
                                    [ngModelOptions]="{standalone: true}" (change)="onChange()" />
                            </span>
                        </div>
                            <div *ngIf="documentTypeId ==1">
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">Licence Required:</label>
                                    <select
                                        style="display: inline-block; width: 310px!important;margin-left: 49px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.licenceRequired"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()">
                                        <option *ngFor="let li of licenceRequired" [value]="li.id">
                                            {{ li.text }}
                                        </option>
                                    </select>
                                </span>
                                <span style="white-space: nowrap;">
                                    <label class="label-custom" style="margin-left:50px;">Licence Expiry
                                        Date:</label>
                                    <input type="date" id="documentExpiryDate" ng [min]="minDate"
                                        style="display: inline-block; max-width: fit-content!important;  margin-left:80px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.licenceExpiryDate"
                                        [ngModelOptions]="{standalone: true}" (change)="onDateChange($event)" />
                                </span>
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">Licence Name:</label>
                                    <input type="text" id="documentLicenceName"
                                        style="display: inline-block;  max-width: 310px!important;  margin-left:68px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.licenceName"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()" />
                                    <label class="label-custom" style="margin-left:50px;">Licence
                                        Classification:</label>
                                    <select
                                        style="display: inline-block; width: 310px!important;margin-left: 65px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.licenceClassificationId"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()">
                                        <option *ngFor="let lc of licenceClassifications| async" [value]="lc.id">
                                            {{ lc.classification }}
                                        </option>
                                    </select>
                                </span>
                                <span style="white-space: nowrap;display: flex;">
                                    <label class="label-custom">Licence Number:</label>
                                    <input type="text" id="documentLicenceNumber" ng [min]="minDate"
                                        style="display: inline-block;  max-width: 310px!important;  margin-left:55px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.licenceNumber"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()" />
                                    <label class="label-custom" style="align-content: center;margin-left:50px;">Licence
                                        Conditions:</label>
                                    <textarea type="text" id="licenceConditions" class="form-control"
                                        style="display: inline-block; margin-left:83px;margin-right:10px ;margin-bottom: 5px;margin-top: 5px; width:310px!important "
                                        class="form-control" [(ngModel)]="documentTypeDetails.licenceConditions"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()"></textarea>
                                </span>
                            </div>
                            <div *ngIf="documentTypeId ==2">
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">P/L Expiry Date:</label>
                                    <input type="date" id="documentPLExpiryDate" ng [min]="minDate"
                                        style="display: inline-block; max-width: fit-content!important;  margin-left:61px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.plExpiryDate"
                                        [ngModelOptions]="{standalone: true}" (change)="onDateChange($event)" />

                                    <label class="label-custom" style="margin-left: 194px;">P/L Insurer:</label>
                                    <select
                                        style="display: inline-block; width: 310px!important;margin-left: 131px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.plInsurerId"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()">
                                        <option *ngFor="let plc of insurers| async" [value]="plc.id">
                                            {{ plc.insurer }}
                                        </option>
                                    </select>
                                </span>
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">P/L Policy Number:</label>
                                    <input type="text" id="documentPLPolicyNumber" ng [min]="minDate"
                                        style="display: inline-block;  max-width: 310px!important;  margin-left:40px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.plPolicyNumber"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()" />
                                    <label class="label-custom" style="margin-left: 50px;">P/L Sum Insured:</label>
                                    <input type="text" id="documentPLSumInsured" ng [min]="minDate"
                                        style="display: inline-block;  max-width: 310px!important;  margin-left:97px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.plSumInsured"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()" />
                                </span>
                            </div>
                            <div *ngIf="documentTypeId ==3">
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">W/C Policy Expiry Date:</label>
                                    <input type="date" id="documentWCPolicyExpiryDate" ng [min]="minDate"
                                        style="display: inline-block; max-width: fit-content!important;  margin-left:15px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.policyExpiryDate"
                                        [ngModelOptions]="{standalone: true}" (change)="onDateChange($event)" />
                                    <label class="label-custom" style="margin-left: 194px;">W/C Insurer:</label>
                                    <select
                                        style="display: inline-block; width: 310px!important;margin-left: 128px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.wcsaInsurerId"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()">
                                        <option *ngFor="let wci of insurers| async" [value]="wci.id">
                                            {{ wci.insurer }}
                                        </option>
                                    </select>
                                </span>
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">W/C Policy Number:</label>
                                    <input type="text" id="documentWCPolicyNumber"
                                        style="display: inline-block;  max-width: 310px!important;  margin-left:35px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.policyNumber"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()" />
                                    <label class="label-custom" style="margin-left:50px;">W/C Sum Insured:</label>
                                    <input type="text" id="documentPLSumInsured"
                                        style="display: inline-block;  max-width: 310px!important; margin-bottom: 5px;margin-top: 5px;margin-left:93px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.policySumInsured"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()" />
                                </span>
                            </div>
                            <div *ngIf="documentTypeId ==4">
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">Construction Induction:</label>
                                    <select
                                        style="display: inline-block; width: 310px!important;margin-left: 14px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.constructionInduction"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()">
                                        <option *ngFor="let ci of constructionInduction" [value]="ci.id">
                                            {{ ci.text }}
                                        </option>
                                    </select>
                                    <label class="label-custom" style="margin-left: 50px;">Provided Date:</label>
                                    <input type="date" id="documentExpiryDate" ng [min]="minDate"
                                        style="display: inline-block; width: fit-content!important;  margin-left:115px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control"
                                        [(ngModel)]="documentTypeDetails.documentDetail==null?'': this.documentTypeDetails.documentDetail.expireDate"
                                        [ngModelOptions]="{standalone: true}" (change)="onDateChange($event)" />
                                </span>
                            </div>
                            <div *ngIf="documentTypeId ==5">
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">SWMS Required:</label>
                                    <select
                                        style="display: inline-block; width: 310px!important;margin-left: 57px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.swmsRequired"
                                        [ngModelOptions]="{standalone: true}" (change)="onChange()">
                                        <option *ngFor="let swm of swmsRequired" [value]="swm.id">
                                            {{ swm.text }}
                                        </option>
                                    </select>
                                    <label class="label-custom" style="margin-left: 50px;">Provided Date:</label>
                                    <input type="date" id="documentExpiryDate" ng [min]="minDate"
                                        style="display: inline-block; width: fit-content!important;  margin-left:115px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control" [(ngModel)]="documentTypeDetails.swmsProvidedDate"
                                        [ngModelOptions]="{standalone: true}" (change)="onDateChange($event)" />
                                </span>
                            </div>
                            <div *ngIf="documentTypeId >=6">
                                <span style="white-space: nowrap;">
                                    <label class="label-custom">Provided Date:</label>
                                    <input type="date" id="documentExpiryDate" ng [min]="minDate"
                                        style="display: inline-block; width: fit-content!important;  margin-left:70px;margin-bottom: 5px;margin-top: 5px;"
                                        class="form-control"
                                        [(ngModel)]="documentTypeDetails.documentDetail==null?'': this.documentTypeDetails.documentDetail.expireDate"
                                        [ngModelOptions]="{standalone: true}" (change)="onDateChange($event)" />
                                </span>
                            </div>
                            <div *ngIf="documentTypeId >0">
                                <span style="white-space: nowrap;display: flex;">
                                    <input type="file" id="fileUpload" formControlName="documentFile"
                                        class="form-control"
                                        style="display: inline-block; width:471px!important; margin-bottom: 5px;margin-top: 5px;"
                                        accept="application/pdf,image/png,image/jpeg,image/gif"
                                        (change)="onFileSelected($event)" #fileUpload />
                                    <label class="label-custom"
                                        style="align-content: center;margin-left: 50px;">Comment:</label>
                                    <textarea type="text" id="comment" class="form-control" formControlName="comment"
                                        style="display: inline-block; margin-left:141px;margin-right:10px ;margin-bottom: 5px;margin-top: 5px; width:310px!important "
                                        class="form-control" [(ngModel)]="documentComment"
                                        (change)="onChange()"></textarea>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="pdfSrc && isPdf" style="display: flex; justify-content: center;">
                            <pdf-viewer [src]="pdfSrc" [rotation]="0" [original-size]="false" [show-all]="true"
                                [fit-to-page]="true" [zoom]=".97" [zoom-scale]="'page-width'" [stick-to-page]="true"
                                [render-text]="true" [show-borders]="true"
                                style="width: 100%; height:220px;"></pdf-viewer>
                        </div>
                        <div *ngIf="pdfSrc && !isPdf" style="display: flex; justify-content: center;">
                            <img [src]="pdfSrc" alt="Image" style="max-height:220px;">
                        </div>
                    </mat-card-content>
                    <mat-card-actions class="mat-card-actions" fxLayoutAlign="center center">
                        <span style="white-space: nowrap;">
                            <button appDisableMultipleClicks [debounceTimeMs]="10" mat-raised-button type="button"
                                (click)="onSubmit()" mat-raised-button class="btn btn-sm btn-primary"
                                [disabled]="this.selectedFiles.length == 0 || isLoading || isDupicateTrade">Add Doc</button>
                            <span style="white-space: nowrap;">
                                <button mat-raised-button mat-dialog-close type="button"
                                    style="margin-left:10px;margin-bottom: 10px;" class="btn btn-success"
                                    [disabled]="isLoading">Cancel</button>
                            </span>
                            <span style="white-space: nowrap;">
                                <button mat-raised-button type="button" style="margin-left:10px;margin-bottom: 10px;"
                                    (click)="deleteDocument()" class="btn btn-danger"
                                    [disabled]="documentTypeDetails.documentDetail==null || isChanged">Delete
                                    Doc</button>
                            </span>
                        </span>
                    </mat-card-actions>
                </mat-card>
            </mat-dialog-content>
        </form>
    </section>