export const NIBInstructions: string = "NIB Instructions"; 
export const NIBQuoteRequest : string  ="NIB Quote Request"; 
export const RRInstructions : string = "RR Instructions"; 
export const RRQuoteRequest : string = "RR Quote Request"; 
export const Mk3Instructions : string = "Mk3 Instructions"; 
export const AppId: number = 11;
export const DocumentUploadPermission : number[] =[46,258];// Michael Doran,Troy McFadden
export const FileTypes =['pdf','png','gif','jpg','jpeg'];
export const PdfType ="application/pdf";
export const ActionRequiredForSA = "John Kontalipos";
export const LargeLossApproval = "Jonathan Shelley";
export const Success = "Success";
export const LislEmail ='lisl.dobson@mk3.com.au';