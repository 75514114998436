import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContractorTrade } from '../_models/contractor-trade';
import { Trade } from '../_models/trade';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Contractor } from '../_models/contractor';
import { AlertService } from '../_services';
import { Contact } from '../_models/contact';
import { Insurance } from '../_models/insurance';
import { Licencing } from '../_models/licencing';
import { State } from '../_models/state';
import { GeoRegion } from '../_models/georegion';
import { EntityType } from '../_models/entitytype';
import { Status } from '../_models/status';
import { AccountService } from './account.service';
import { Insurer } from '../_models/insurer';
import { LicenceClassification } from '../_models/licence-classification';
import { Licence } from '@app/_models/licence';
import { Note } from '@app/_models/note';
import { NoteType } from '@app/_models/noteTypes';
import { InstructionType } from '@app/_models/instructionType';
import { ADUser } from '@app/_models';
import { InternalTrade } from '@app/_models/internal-trade';
import { EmailRecord } from '@app/_models/email-record';
import { DatumNote } from '@app/_models/datumNote';
import { WorkItem } from '@app/_models/work-item';
import { ClaimOwnerDetails } from '@app/_models/claim-owner-details';
import { JobHistory } from '@app/_models/job-history';
import { JobStatus } from '@app/_models/job-status';
import { JobFile } from '@app/_models/job-file';
import { QualityRating } from '@app/_models/quality-rating';
import { EmailRecordExPdfData } from '@app/_models/email-record-ex-pdfdata';
import { JobHistoryView } from '@app/_models/job-history-view';
import { Employee } from '@app/_models/employee';
import { ADUserManager } from '@app/_models/user-manager-details-ad';
import { AppId } from '@app/_helpers/constants';
import { groups } from '@app/auth-config';
import { GlobalValues } from './global-values';
import { DocumentType } from '@app/_models/document-type';
import { DocumentDetail } from '@app/_models/document-detail';
import { DocumentRecords } from '@app/_models/document-records';
import { CommentOnly } from '@app/_models/comment-only';
import { DocumentTypeDetails } from '@app/_models/document-type-details';
import { pagedVClaim } from '@app/_models/paged-vclaim';
import { TncAgreement } from '@app/_models/tncAgreement';
@Injectable({
  providedIn: 'root',
})
export class RestService {
  errorMsg: string = '';
  statusesArray: Array<Status> = [];

  //Inject the HttpClient module to the constructor params
  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private accountService: AccountService,
    public globalValues: GlobalValues
  ) {
    this.getStatusTypes().subscribe((res: Status[]) => {
      this.statusesArray = res;
    });
  }

  uploadPayments(ids: number[], name:string, email:string): Observable<any>{
    let params = new HttpParams();
      ids.forEach(id => params = params.append('ids', id.toString()));
      params = params.append('name', name);
      params = params.append('email', email);
      return this.http.get<any>(
        `${environment.contractorAPIMainUrl}EmailRecords/PaymentsUpload`+ 
        this.accountService.subscriptionKey,
        { params: params }
    );//.pipe(catchError(this.handleError));
  }

  //GET the list of contractors from the REST API using HttpClient
  getContractors(): Observable<Contractor[]> {
    return this.http
      .get<Object[]>(
        `${environment.contractorAPIMainUrl}` +
          'ContractorDetails' +
          this.accountService.subscriptionKey
      )
      .pipe(
        // use the map() operator to return the data property of the response object
        // the operator enables to map the response of the Observable stream to the data value
        map((data) => data.map((obj) => this.toObject(obj))),
        catchError((error) => {
          this.alertService.clear();
          this.alertService.error(error);
          //Handle the errors
          return throwError(error); //Rethrow it back to component
        })
      );
  }

  getMainDetails(id: number) {
    return this.http.get<Contractor>(
      `${environment.contractorAPIMainUrl}` +
        'ContractorDetails/' +
        id +
        this.accountService.subscriptionKey
    );
  }

  //GET Contact details by ID (contractorId)
  getContactDetails(id: number) {
    return this.http.get<Contact>(
      `${environment.contractorAPIMainUrl}` +
        'Contacts/' +
        id +
        this.accountService.subscriptionKey
    );
  }

  //GET Contact details by ID (contractorId)
  getContractorDetails(id: number | null) {
    return this.http.get<Contractor>(
      `${environment.contractorAPIMainUrl}` +
        'ContractorDetails/' +
        id +
        this.accountService.subscriptionKey
    );
  }

  //GET Insurance details by ID (contractorId)
  getInsuranceDetails(id: number) {
    return this.http.get<Insurance>(
      `${environment.contractorAPIMainUrl}` +
        'Insurances/' +
        id +
        this.accountService.subscriptionKey
    );
  }

  //GET Licencing details by ID (contractorId)
  getLicencingDetails(id: number) {
    return this.http.get<Licencing>(
      `${environment.contractorAPIMainUrl}` +
        'Licencings/' +
        id +
        this.accountService.subscriptionKey
    );
  }

  //GET the full list of Trades available
  getTrades(): Observable<Trade[]> {
    return this.http
      .get<Object[]>(
        `${environment.contractorAPIMainUrl}` +
          'TradeServices' +
          this.accountService.subscriptionKey
      )
      .pipe(
        map((trade: any) =>
          trade.filter((trade: any) => trade.contractorsEnabled === true)
        ),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //GET the list of Trades selected for a given Contractor
  getSelectedTrades(id: number): Observable<ContractorTrade[]> {
    return this.http
      .get<Object[]>(
        `${environment.contractorAPIMainUrl}` +
          'ContractorTrades/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(
        map((data) => data.map((obj) => this.toContractorTrade(obj))),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  getStates() {
    return this.http
      .get<State>(
        `${environment.commonAPIMainUrl}` +
          'States' +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }

  //GET State Name by ID from the CommonAPI
  getState(id: number) {
    return this.http
      .get<State>(
        `${environment.commonAPIMainUrl}` +
          'States/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(map((State) => State));
  }

  
  //GET State Manager Details by State ID from the CommonAPI
  getEmployeeDetailsByEmail(email: string) {
    return this.http
      .get<Employee>(
        `${environment.commonAPIMainUrl}` +
          'Employees/GetEmployeeByEmail/' + email + this.accountService.subscriptionKey
      )
      .pipe(map((Employee) => Employee));
  }

  //GET State Manager Details by State ID from the CommonAPI
  getStateManagerDetails(stateId: number) {
    return this.http
      .get<Employee>(
        `${environment.commonAPIMainUrl}` +
          'Employees/GetStateManagerForStateId/' + stateId + this.accountService.subscriptionKey
      )
      .pipe(map((Employee) => Employee));
  }

  //GET State Manager Details by Employee ID from the CommonAPI
  GetStateManagerForEmployee(employeeId: number) {
    return this.http
      .get<Employee>(
        `${environment.commonAPIMainUrl}` +
          'Employees/GetStateManagerForEmployee/' + employeeId + this.accountService.subscriptionKey
      )
      .pipe(map((Employee) => Employee));
  }

    //GET State Manager Details by Employee Email from the CommonAPI
    GetStateManagerForEmployeeEmail(email: string) {
      return this.http
        .get<Employee>(
          `${environment.commonAPIMainUrl}` +
            'Employees/GetStateManagerForEmployeeEmail/' + email + this.accountService.subscriptionKey
        )
        .pipe(map((Employee) => Employee));
    }

  //GET List of Geo Regions from the CommonAPI
  getGeoRegions(): Observable<any> {
    return this.http
      .get<GeoRegion>(
        `${environment.commonAPIMainUrl}` +
          'Mk3GeoRegions' +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }

  //GET GeoRegion from the CommonAPI
  getGeoRegion(id: number) {
    return this.http
      .get<GeoRegion>(
        `${environment.commonAPIMainUrl}` +
          'Mk3GeoRegions/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(map((GeoRegion) => GeoRegion));
  }

  addGeoRegion(geoRegion: GeoRegion) {
    return this.http.post(
      `${environment.commonAPIMainUrl}Mk3GeoRegions` +
        this.accountService.subscriptionKey,
      geoRegion
    );
  }

  updateGeoRegion(id: string, params: any) {
    return this.http
      .put(
        `${environment.commonAPIMainUrl}Mk3GeoRegions/${id}` +
          this.accountService.subscriptionKey,
        params
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //GET List of ADUsers from the CommonAPI
  getADUsers(): Observable<any> {
    return this.http
      .get<ADUser>(
        `${environment.commonAPIMainUrl}` +
          'ADUsers' +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }

  //GET List of ADUser Details from the CommonAPI
  getADUser(id: number): Observable<any> {
    return this.http
      .get<ADUser>(
        `${environment.commonAPIMainUrl}` +
          'ADUsers/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }

  //GET InternalTrades
  getInternalTrades(): Observable<any> {
    return this.http
      .get<any[]>(
        `${environment.commonAPIMainUrl}` +
          'VInternalTrades' +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }
  //GET InternalTrade by Employee ID from the CommonAPI
  getInternalTrade(id: number | null): Observable<any> {
    return this.http
      .get<InternalTrade[]>(
        `${environment.commonAPIMainUrl}` +
          'VInternalTrades/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }

  getEntityTypes() {
    return this.http
      .get<EntityType>(
        `${environment.contractorAPIMainUrl}` +
          'EntityTypes' +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }

  getEntityType(id: number) {
    return this.http
      .get<EntityType>(
        `${environment.contractorAPIMainUrl}` +
          'EntityTypes/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(map((EntityType) => EntityType));
  }

  getStatusTypes(): Observable<any> {
    return this.http
      .get<Status>(
        `${environment.contractorAPIMainUrl}` +
          'Statuses' +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }

  getStatus(id: number) {
    return this.http
      .get<Status>(
        `${environment.contractorAPIMainUrl}` +
          'Statuses/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(map((Status) => Status));
  }

  getTradeCategories(): Observable<any> {
    return this.http
      .get<Trade>(
        `${environment.contractorAPIMainUrl}` +
          'TradeServices' +
          this.accountService.subscriptionKey
      )
      .pipe(
        map((trade: any) =>
          trade.filter((trade: any) => trade.contractorsEnabled === true)
        ),
        catchError(this.handleError)
      );
  }

  getTradeCategory(id: number) {
    return this.http
      .get<Trade>(
        `${environment.contractorAPIMainUrl}` +
          'TradeServices/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(map((Trade) => Trade));
  }

  addTradeCategory(tradeCategory: Trade) {
    return this.http.post(
      `${environment.contractorAPIMainUrl}TradeServices` +
        this.accountService.subscriptionKey,
      tradeCategory
    );
  }

  updateTradeCategory(id: string, params: any) {
    return this.http
      .put(
        `${environment.contractorAPIMainUrl}TradeServices/${id}` +
          this.accountService.subscriptionKey,
        params
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  getTradeClassifications(id: number) {
    return this.http
      .get<Trade>(
        `${environment.contractorAPIMainUrl}` +
          'TradeClassifications/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(map((TradeClassification) => TradeClassification));
  }

  getInsurers(): Observable<any> {
    return this.http
      .get<Insurer>(
        `${environment.contractorAPIMainUrl}` +
          'Insurers' +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }

  getInsurer(id: number) {
    return this.http
      .get<Insurer>(
        `${environment.contractorAPIMainUrl}` +
          'Insurers/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(map((Insurer) => Insurer));
  }

  addInsurer(insurer: Insurer) {
    return this.http.post(
      `${environment.contractorAPIMainUrl}Insurers` +
        this.accountService.subscriptionKey,
      insurer
    );
  }

  updateInsurer(id: string, params: any) {
    return this.http
      .put(
        `${environment.contractorAPIMainUrl}Insurers/${id}` +
          this.accountService.subscriptionKey,
        params
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  getLicenceClassifications(): Observable<any> {
    return this.http
      .get<LicenceClassification>(
        `${environment.contractorAPIMainUrl}` +
          'LicenceClassifications' +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }

  getLicenceClassification(id: number) {
    return this.http
      .get<LicenceClassification>(
        `${environment.contractorAPIMainUrl}` +
          'LicenceClassifications/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(map((LicenceClassification) => LicenceClassification));
  }

  addLicenceClassification(licence: Licence) {
    return this.http.post(
      `${environment.contractorAPIMainUrl}LicenceClassifications` +
        this.accountService.subscriptionKey,
      licence
    );
  }

  updateLicenceClassification(id: string, params: any) {
    return this.http
      .put(
        `${environment.contractorAPIMainUrl}LicenceClassifications/${id}` +
          this.accountService.subscriptionKey,
        params
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  private createHeader(contentType: string): any {
    return {
      headers: new HttpHeaders({ 'Content-Type': contentType }),
      responseType: 'text',
    };
  }

  async deleteTrades(id: number) {
    const result = await this.http
      .delete(
        `${environment.contractorAPIMainUrl}` +
          'ContractorTrades/' +
          id +
          this.accountService.subscriptionKey,
        this.createHeader('application/json')
      )
      .toPromise(); //.subscribe(data => {
    return result;
  }

  addTrades(id: number, trades: number[]) {
    return this.http
      .post(
        `${environment.contractorAPIMainUrl}` +
          'ContractorTrades' +
          this.accountService.subscriptionKey +
          '&id=' +
          id,
        trades
      )
      .subscribe({
        next: (data) => {
          data.toString;
        },
        error: (error: any) => {
          console.error('Error - addTrades() ', error.toString());
        },
      });
  }

  getNoteTypes() {
    return this.http
      .get<NoteType[]>(
        `${environment.contractorAPIMainUrl}` +
          'NoteTypes' +
          this.accountService.subscriptionKey
      )
      .pipe(
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  getNoteType(id: number): string {
    let retVal = '';
    if (id > 0) {
      this.http
        .get<NoteType>(
          `${environment.contractorAPIMainUrl}` +
            'NoteTypes/' +
            id +
            this.accountService.subscriptionKey
        )
        .subscribe((data) => {
          if (data) retVal = data.type;
          else retVal = '';
        });
    } else retVal = '';

    return retVal;
  }

  //GET Notes by ID (refNo)
  getNotes(id: number): Observable<Note[]> {
    return this.http
      .get<Object[]>(
        `${environment.contractorAPIMainUrl}` +
          'Notes/' +
          id.toString() +
          this.accountService.subscriptionKey
      )
      .pipe(
        map((data) => data.map((obj) => this.toNoteDetails(obj))),
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  getStatusDefinition(statusId: number): string {
    const result = this.statusesArray.find(
      (element) => element.statusId === statusId
    );
    if (result) return result.definition;
    else return '';
  }

  //Convert the result to a Note object and return
  private toNoteDetails(obj: any): Note {
    return {
      contractorId: obj.contractorId,
      noteTypeId: obj.noteTypeId,
      refNo: obj.refNo,
      addedBy: obj.addedBy,
      comments: obj.comments,
      timeStamp: obj.timeStamp,
    };
  }

  addNewNote(note: Note) {
    return this.http.post(
      `${environment.contractorAPIMainUrl}Notes` +
        this.accountService.subscriptionKey,
      note
    );
  }

  getMaxVersionByContractorID(id: number): Observable<number> {
    return this.http.get<number>(
      `${environment.contractorAPIMainUrl}ContractorSignedAgreement/GetMaxVersionByContractorID/` + id.toString() +
        this.accountService.subscriptionKey
    );
  }

  getTncAgreementByContractorId(id: number): Observable<TncAgreement[]> {
    return this.http
      .get<Object[]>(
        `${environment.contractorAPIMainUrl}ContractorSignedAgreement/GetByContractorID/` +
          id.toString() +
          this.accountService.subscriptionKey
      )
      .pipe(
        map((data) => data.map((obj) => this.toTncAgreement(obj))),
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  addTncAgreement(tnc:TncAgreement){
    return this.http.post<HttpResponse<any>>(
      `${environment.contractorAPIMainUrl}ContractorSignedAgreement` +
        this.accountService.subscriptionKey,
      tnc
      ,{ observe: 'response' }
    );
  }

  //Convert the result to a Contractor object and return
  private toObject(obj: any): Contractor {
    const contractor = obj.contractor;
    return {
      contractorId: obj.contractorId,
      tradingName: obj.tradingName,
      companyName: obj.companyName,
      acn: obj.acn,
      abn: obj.abn,
      dateLastUsed: obj.dateLastUsed,
      dateAdded: obj.dateAdded,
      dateEvaluated: obj.dateEvaluated,
      compliantUntil: obj.compliantUntil,
      compliancyNotes: obj.compliancyNotes,//?.replace('<br>','\n'),//?.replace('<br>', /(?:\r\n|\r|\n)/g),
      entityTypeId: obj.entityTypeId,
      statusId: obj.statusId,
      policyExpiryDate: obj.policyExpiryDate,
      licenceExpiryDate: obj.licenceExpiryDate,
      plexpiryDate: obj.plexpiryDate,
      policyStatus: obj.policyStatus,
      licenceStatus: obj.licenceStatus,
      pLStatus: obj.pLStatus,
      complianceStatus: obj.complianceStatus,
      isCompliant: obj.isCompliant,
      stateId: obj.stateId,
      geoRegionId: obj.geoRegionId,
      state: obj.state,
      geoRegion: obj.geoRegion,
      status: obj.status,
      tradeServices: obj.tradeServices,
      mainContactName: obj.mainContactName,
      mainContactPhone: obj.mainContactPhone,
      mainContactEmail: obj.mainContactEmail,
      postcode: obj.postcode,
      suburb:obj.suburb,
      tncURL:obj.tncURL,
      tncStatus:obj.tncStatus,
      mainTradeCategoryId: obj.mainTradeCategoryId,
      mainTradeCategory: obj.mainTradeCategory,
      licenceRequired: obj.licenceRequired,
      lastModifiedBy: obj.lastModifiedBy,
      lastModifiedOn: obj.lastModifiedOn,
      addedBy: obj.addedBy,
      addedOn: obj.addedOn,
      avgJTMRating: obj.avgJTMRating,
      avgQoWRating: obj.avgQoWRating,
      avgHSERating: obj.avgHSERating,
      avgRating: obj.avgRating,
      swmsRequired:obj.swmsRequired,
      swmsProvidedDate: obj.swmsProvidedDate,
    };
  }

  //Convert the result to a Case object and return
  private toContractorTrade(obj: any): ContractorTrade {
    return {
      id: obj.id,
      contractorId: obj.contractorId,
      tradeId: obj.tradeId,
      licenceClassificationId: obj.licenceClassificationId,
    };
  }

  private toDocumentDetails(obj: any): DocumentRecords {
    return {
      documentId:obj.documentId,
      contractorId: obj.contractorId,
      documentType: obj.documentType,
      documentTypeId: obj.documentTypeId,
      documentData:obj.documentData,
      expireDate:obj.expireDate,
      addedById:obj.addedById,
      addedOn:obj.addedOn,
      comment:obj.comment,
      addedBy: obj.addedBy,
      fileType : obj.fileType
    };
  }

  private toTncAgreement(obj: any): TncAgreement {
    return {
      id:obj.id,
      contractorId: obj.contractorId,
      documentTitle: obj.documentTitle,
      dateSigned: obj.dateSigned,
      dateSent: obj.dateSent,
      versionNo: obj.versionNo,
      signedPdfData: obj.signedPdfData,
      signeeName: obj.signeeName,
      signeeTitle:obj.signeeTitle,
    };
  }

  //Error handler
  /*private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      //this.alertService.error(error.error.message);
      return error;
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      //this.alertService.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
      return error;
    }
    // return throwError(
    //   'Error occured; please try again later.');
  }*/

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Unknown error occurred';

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    errorMessage += '\n\n<b><u>Please report this error to the IT Department with a screenshot of your full page.</u></b>';
    // Rethrow the error
    return throwError(() =>errorMessage);
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }

  //Add Contractor Details
  async addContractorMain(contractor: Contractor) {
    contractor.contractorId = 0;
    let id = 0;
    await this.http
      .post(
        `${environment.contractorAPIMainUrl}ContractorMains` +
          this.accountService.subscriptionKey,
        contractor
      )
      .toPromise()
      .then((data) => {
        id = JSON.parse(JSON.stringify(data)).contractorId;
      });
    return id;
  }

  //Add Contact Details
  addContacts(contact: Contact, contractorId: number) {
    contact.contactId = 0;
    contact.contractorId = contractorId;
    return this.http
      .post(
        `${environment.contractorAPIMainUrl}Contacts` +
          this.accountService.subscriptionKey,
        contact
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //Add Insurance Details
  addInsuranceDetails(insurance: Insurance, contractorId: number) {
    insurance.insuranceId = 0;
    insurance.contractorId = contractorId;
    return this.http
      .post(
        `${environment.contractorAPIMainUrl}Insurances` +
          this.accountService.subscriptionKey,
        insurance
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //Add Licence Details
  addLicenceDetails(licencing: Licencing, contractorId: number) {
    licencing.licencingId = 0;
    licencing.contractorId = contractorId;
    return this.http
      .post(
        `${environment.contractorAPIMainUrl}Licencings` +
          this.accountService.subscriptionKey,
        licencing
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //Update Contractor Main Details
  updateContractorMain(id: number, params: any) {
    return this.http
      .put(
        `${environment.contractorAPIMainUrl}ContractorMains/${id}` +
          this.accountService.subscriptionKey,
        params
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //Update Contact Details
  updateContacts(id: number, params: any) {
    return this.http
      .put(
        `${environment.contractorAPIMainUrl}Contacts/${id}` +
          this.accountService.subscriptionKey,
        params
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //Update Insurance Details
  updateInsuranceDetails(id: number, params: any) {
    return this.http
      .put(
        `${environment.contractorAPIMainUrl}Insurances/${id}` +
          this.accountService.subscriptionKey,
        params
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //Update Licence Details
  updateLicenceDetails(id: number, params: any) {
    return this.http
      .put(
        `${environment.contractorAPIMainUrl}Licencings/${id}` +
          this.accountService.subscriptionKey,
        params
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          this.alertService.clear();
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //Get case details by RefNo
  getCase(id: number): Observable<any> {
    return this.http
      .get<any>(
        `${environment.casesApiUrl}` +
          '/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(catchError(this.handleError));
  }

  getInstructionTypes(isInternalOnly: boolean) {
    if (isInternalOnly)
      return this.http.get<InstructionType>(
        `${environment.contractorAPIMainUrl}` +
          'InstructionTypes/InternalOnly' +
          this.accountService.subscriptionKey
      );
    else
      return this.http.get<InstructionType>(
        `${environment.contractorAPIMainUrl}` +
          'InstructionTypes' +
          this.accountService.subscriptionKey
      );
  }

  sendEmail(email: any, log:string=''): any {
  let params = new HttpParams().set('log', log);
  return this.http
    .post(
      `${environment.contractorAPIMainUrl}` +
        'SendEmail/PostContact' +
        this.accountService.subscriptionKey,
      email, 
      { params }//{ headers: headers, observe: 'response' }
    )
    .pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

    // let headers = new HttpHeaders();
  // headers = headers.set('Content-Type', 'application/json');
  // var url = `${environment.contractorAPIMainUrl}` +
  //       'SendEmail/' +encodeURIComponent(log)+
  //       this.accountService.subscriptionKey;
  // sendEmail(email: any, user:string=''): any {
  //   // let headers = new HttpHeaders();
  //   // headers = headers.set('Content-Type', 'application/json');
  //   if (user!==''){
  //     let params = new HttpParams().set('user', user);
  //     return this.http
  //     .post(
  //       `${environment.contractorAPIMainUrl}` +
  //         'SendEmail/PostEmail' +
  //         this.accountService.subscriptionKey,
  //       email, 
  //       { params }//{ headers: headers, observe: 'response' }
  //     )
  //     .pipe(
  //       map((data) => {
  //         return data;
  //       }),
  //       catchError(this.handleError)
  //     );
  //   }
  //   else {
  //     return this.http
  //     .post(
  //       `${environment.contractorAPIMainUrl}` +
  //         'SendEmail' + 
  //         this.accountService.subscriptionKey,
  //       email, 
  //     )
  //     .pipe(
  //       map((data) => {
  //         return data;
  //       }),
  //       catchError(this.handleError)
  //     );
  //   }
  // }
    
  addNewEmailRecord(record: EmailRecord) {
    return this.http
      .post(
        `${environment.contractorAPIMainUrl}` +
          'EmailRecords' +
          this.accountService.subscriptionKey,
        record
      )
      .pipe(
        map((x) => {
          return JSON.parse(JSON.stringify(x)).id;
        }),
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  getEmailRecord(id: number): Observable<EmailRecord> {
    return this.http.get<EmailRecord>(
      `${environment.contractorAPIMainUrl}` +
        'EmailRecords/' +
        id +
        this.accountService.subscriptionKey
    );
  }

  getEmailRecordsByContractorId(
    contractorID: number
  ): Observable<EmailRecordExPdfData[]> {
    return this.http.get<EmailRecordExPdfData[]>(
      `${environment.contractorAPIMainUrl}` +
        'EmailRecordsExPdfData/GetByContractorId/' +
        contractorID +
        this.accountService.subscriptionKey
    );
  }

  
  getQuotesByContractorId(
    contractorID: number
  ): Observable<EmailRecordExPdfData[]> {
    return this.http.get<EmailRecordExPdfData[]>(
      `${environment.contractorAPIMainUrl}` +
        'EmailRecordsExPdfData/GetContractorQuotes/' +
        contractorID +
        this.accountService.subscriptionKey
    );
  }

  getInstructionsAllByContractorId(
    contractorID: number
  ): Observable<EmailRecordExPdfData[]> {
    return this.http.get<EmailRecordExPdfData[]>(
      `${environment.contractorAPIMainUrl}` +
        'EmailRecordsExPdfData/GetContractorInstructionsAll/' +
        contractorID +
        this.accountService.subscriptionKey
    );
  }

  getInstructionsOpenByContractorId(
    contractorID: number
  ): Observable<EmailRecordExPdfData[]> {
    return this.http.get<EmailRecordExPdfData[]>(
      `${environment.contractorAPIMainUrl}` +
        'EmailRecordsExPdfData/GetContractorInstructionsOpen/' +
        contractorID +
        this.accountService.subscriptionKey
    );
  }

  getInstructionsClosedByContractorId(
    contractorID: number
  ): Observable<EmailRecordExPdfData[]> {
    return this.http.get<EmailRecordExPdfData[]>(
      `${environment.contractorAPIMainUrl}` +
        'EmailRecordsExPdfData/GetContractorInstructionsClosed/' +
        contractorID +
        this.accountService.subscriptionKey
    );
  }


  getEmailRecordsByInternalTradeId(
    internalTradeID: number
  ): Observable<EmailRecordExPdfData[]> {
    return this.http.get<EmailRecordExPdfData[]>(
      `${environment.contractorAPIMainUrl}` +
        'EmailRecordsExPdfData/GetByInternalTradeId/' +
        internalTradeID +
        this.accountService.subscriptionKey
    );
  }

  //Get the Maximum InstructionItemId for a given RefNo + Contractor/Internal Trade ID + Instruction TypeId
  getMaxInstructionItemId(
    refNo: number,
    contractorId?: number,
    internalTradeId?: number,
    instructionTypeId?: number
  ): Observable<number> {
    return this.http.get<number>(
      `${environment.contractorAPIMainUrl}EmailRecords/GetMaxInstructionItemId/` +
        refNo +
        '/' +
        (contractorId ? contractorId : 0) +
        '/' +
        (internalTradeId ? internalTradeId : 0) +
        '/' +
        instructionTypeId +
        this.accountService.subscriptionKey
    );
  }

  addNewNoteToDatum(note: DatumNote) {
    return this.http
      .post(
        `${environment.wgilAPIBaseUrl}` +
          'WgilClaimNotes' +
          this.accountService.subscriptionKey,
        note
      )
      .pipe(
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //Get the List of Scope of Works for a given RefNo
  getWorkItemsByRefNo(refNo: number): Observable<WorkItem> {
    return this.http.get<WorkItem>(
      `${environment.sowApiUrl}` +
        'vWorkItems/GetWorkItemsByRefNoForContractor/' +
        refNo +
        this.accountService.subscriptionKey
    );
  }

  getClaimOwnerDetails(refNo: number) {
    return this.http
      .get<ClaimOwnerDetails>(
        `${environment.wgilAPIBaseUrl}` +
          'vWGICases/ClaimOwnerDetail/' +
          refNo +
          this.accountService.subscriptionKey
      )
      .pipe(map((ClaimOwnerDetails) => ClaimOwnerDetails));
  }

  //Update the Last Used Date
  updateLastUsedDate(id: number, body: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http
      .patch(
        `${environment.contractorAPIMainUrl}ContractorMains/${id}` +
          this.accountService.subscriptionKey,
        body,
        { headers: headers }
      )
      .subscribe();
  }

  //Update the status of the job
  updateEmailRecord(id: number, statusId: number, body: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.patch(
      `${environment.contractorAPIMainUrl}EmailRecords/${id}/${statusId}` +
        this.accountService.subscriptionKey,
      body,
      { headers: headers }
    );
  }

  //Get the job history by the Job ID
  getJobHistory(jobId: number): Observable<JobHistoryView[]> {
    return this.http.get<JobHistoryView[]>(
      `${environment.contractorAPIMainUrl}` +
        'vJobHistories/GetByJobId/' +
        jobId +
        this.accountService.subscriptionKey
    );
  }

  getJobHistoryByOriginalHistoryId(originalHistoryId: number): Observable<JobHistoryView[]> {
    return this.http.get<JobHistoryView[]>(
      `${environment.contractorAPIMainUrl}` +
        'vJobHistories/GetByOriginalHistoryId/' +
        originalHistoryId +
        this.accountService.subscriptionKey
    );
  }

  //Get the list of approved invoices
  getApprovedInvoices(): Observable<JobHistoryView[]> {
    return this.http.get<JobHistoryView[]>(
      `${environment.contractorAPIMainUrl}` +
        'vJobHistories/GetApprovedInvoices' +
        this.accountService.subscriptionKey
    );
  }

  //Get the list of paid invoices
  getPaidInvoices(): Observable<JobHistoryView[]> {
    return this.http.get<JobHistoryView[]>(
      `${environment.contractorAPIMainUrl}` +
        'vJobHistories/GetPaidInvoices' +
        this.accountService.subscriptionKey
    );
  }

  //Get the list invoices awaiting approval
  getInvoicesAwaitingApproval(): Observable<JobHistoryView[]> {
    return this.http.get<JobHistoryView[]>(
      `${environment.contractorAPIMainUrl}` +
        'vJobHistories/GetInvoicesAwaitingApproval' +
        this.accountService.subscriptionKey
    );
  }

  getRejectedInvoices(): Observable<JobHistoryView[]> {
    return this.http.get<JobHistoryView[]>(
      `${environment.contractorAPIMainUrl}` +
        'vJobHistories/GetRejectedInvoices' +
        this.accountService.subscriptionKey
    );
  }

  //Get the job history record by id
  getJobHistoryView(id: number): Observable<JobHistoryView> {
    return this.http.get<JobHistoryView>(
      `${environment.contractorAPIMainUrl}vJobHistories/` +
        id +
        this.accountService.subscriptionKey
    );
  }

  //Get the total amount of approved invoices for a given job as of now
  getInvoicesApprovedTotal(refNo: number) {
    return this.http.get<number>(
      `${environment.contractorAPIMainUrl}` +
        'vJobHistories/GetClaimInvoicesTotal/' +
        refNo +
        this.accountService.subscriptionKey
    );
  }

  //Get the job file by id
  getInvoicePDF(id: number | null): Observable<JobFile> {
    return this.http.get<JobFile>(
      `${environment.contractorAPIMainUrl}` +
        'JobFiles/' +
        id +
        this.accountService.subscriptionKey
    );
  }

  //Add a new job history record
  addNewJobHistoryRecord(record: JobHistory): Observable<any> {
    return this.http
      .post(
        `${environment.contractorAPIMainUrl}` +
          'JobHistories' +
          this.accountService.subscriptionKey,
        record
      )
      .pipe(
        /*map((x) => {
          return x;
        }),*/


        /*catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return this.errorMsg;// throwError(this.errorMsg);
        })*/
        catchError(this.handleError)
      );
  }

  //Get the list of job statuses
  getJobStatuses(): Observable<JobStatus[]> {
    return this.http.get<JobStatus[]>(
      `${environment.contractorAPIMainUrl}` +
        'JobStatus' +
        this.accountService.subscriptionKey
    );
  }

  //Get the job status by id
  getJobStatus(statusId: number): Observable<JobStatus> {
    return this.http.get<JobStatus>(
      `${environment.contractorAPIMainUrl}` +
        'JobStatus/' +
        statusId +
        this.accountService.subscriptionKey
    );
  }

  //Add a new job file
  addNewJobFile(fileRecord: JobFile) {
    return this.http
      .post(
        `${environment.contractorAPIMainUrl}` +
          'JobFiles' +
          this.accountService.subscriptionKey,
        fileRecord
      )
      .pipe(
        map((x) => {
          return JSON.parse(JSON.stringify(x)).id;
        }),
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  
  //Get QualityRatings by Contractor ID
  getQualityRatingsByContractorId(contractorId: number): Observable<any> {
    return this.http.get(`${environment.contractorAPIMainUrl}QualityRatings/GetByContractorId/` + contractorId + this.accountService.subscriptionKey)
      .pipe(
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //Add new quality rating record
  addNewQualityRating(record: QualityRating): Observable<any> {
    return this.http
      .post(
        `${environment.contractorAPIMainUrl}` +
          'QualityRatings' +
          this.accountService.subscriptionKey,
        record
      )
      .pipe(
        map((x) => {
          return x;
        }),
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  //Get the claim contacts by ID (refNo)
  getClaimContacts(id: number): Observable<any> {
    //}: Observable<Case[]>{
    return this.http
      .get(
        `${environment.claimContactApiUrl}` +
          '/' +
          id +
          this.accountService.subscriptionKey
      )
      .pipe(
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }
          this.alertService.clear();
          this.alertService.error(this.errorMsg);
          return throwError(this.errorMsg);
        })
      );
  }

  getADUserManagerDetails(id: number): Observable<any> {
    return this.http
      .get<ADUserManager>(
        `${environment.commonAPIMainUrl}` +
          'ADUserManager/' +
          id +
          this.accountService.subscriptionKey +'&appId='+ AppId
      )
      .pipe(catchError(this.handleError));
  }
  
  getListOfAllMembersInAssessorLevelZeroGroup(token:any): any {
    const headers = new HttpHeaders({
      Authorization: token,
    });
    const graphApiEndpoint = 'https://graph.microsoft.com/v1.0/';
    const groupId = groups.groupAssessorLevel0;
    return this.http
      .get<any>(`${graphApiEndpoint}groups/${groupId}/members`, { headers })
      .pipe(catchError(this.handleError));
  }

  getDocumentTypes() {
    return this.http
      .get<DocumentType[]>(
        `${environment.contractorAPIMainUrl}` +
          'Document/GetDocumentTypes' +
          this.accountService.subscriptionKey
      )
      .pipe(
        catchError((err) => {
          this.alertService.clear();
          this.alertService.error(err);
          return throwError(err); //Rethrow it back to component
        })
      );
  }

    //Add a new job file
    addNewDocumentDetail(documentTypeDetails: DocumentTypeDetails) {
      return this.http
        .post(
          `${environment.contractorAPIMainUrl}` +
            'Document/PostDocumentFile' +
            this.accountService.subscriptionKey,
            documentTypeDetails
        )}

    getADUserByEmail(email: string): Observable<any> {
      return this.http
        .get<ADUserManager>(
          `${environment.commonAPIMainUrl}` +
            'ADUserManager/email/' +
            email +
            this.accountService.subscriptionKey +
            '&appId=' +
            AppId
        )
        .pipe(catchError(this.handleError));
    }
      //Get DocumentDetail by Contractor ID
      getDocumentDetailByContractorId(contractorId: number): Observable<DocumentRecords[]> {
    return this.http.get<Object[]>(`${environment.contractorAPIMainUrl}Document/GetDocumentDetailByContractorId/` + contractorId + this.accountService.subscriptionKey)
    .pipe(
      map((data) => data.map((obj) => this.toDocumentDetails(obj))),
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }
        this.alertService.clear();
        this.alertService.error(this.errorMsg);
        return throwError(this.errorMsg);
      })
    );
  }
  getDocumentDetailByDocumentId(documentId: number): Observable<any> {
    return this.http.get<DocumentDetail>(`${environment.contractorAPIMainUrl}Document/GetDocumentDetailByDocumentId/` + documentId + this.accountService.subscriptionKey)
    .pipe(catchError(this.handleError));
  }

  sendEmailToStateManager(contractor: Contractor,userName: string, userEmail:string, log:string='') {
    let params = new HttpParams().set('log', log);
    return this.http.put(
      `${environment.contractorAPIMainUrl}SendEmail/SendEmailToManager/`+userName+`/`+userEmail +
        this.accountService.subscriptionKey,
        contractor,
        {params}
    );
  }
  
  addComment(commentOnly: CommentOnly)
  {
    return this.http.put(
      `${environment.contractorAPIMainUrl}JobHistories/AddComments/`+
        this.accountService.subscriptionKey,
        commentOnly
    );
  }
  reopenInstruction(reopenInstruction:CommentOnly):Observable<any>{
    let url =`${environment.contractorAPIMainUrl}` +'JobHistories/ReopenInstructions' + this.accountService.subscriptionKey
    return this.http.put(url,reopenInstruction);
  }
  getDetailsForDocumentByDocumentTypeId(documentTypeId : number,contractorId: number):Observable<any>{
    return this.http.get<any>(`${environment.contractorAPIMainUrl}Document/GetDetailsForDocumentByDocumentTypeId/` + documentTypeId +'/'+ contractorId + this.accountService.subscriptionKey)
    .pipe(catchError(this.handleError));
  }
  deleteDocumentById(documentId: number):Observable<any>{
    return this.http.delete(
      `${environment.contractorAPIMainUrl}Document/DeleteDocumentById/`+documentId+
        this.accountService.subscriptionKey
    );
  }
  getEmailRecordStatusForJobId(jobId:number):Observable<any>
  {
    return this.http.get<number>(`${environment.contractorAPIMainUrl}EmailRecords/GetEmailRecordStatusForJobId/` + jobId + this.accountService.subscriptionKey)
    .pipe(catchError(this.handleError));
  }
  getClaimAmountDetailsForRefNo(refNo: number)
  {
    var url =
      `${environment.wgilAPIBaseUrl}` +
      'vClaimsAll/PagedClaimRecords' +
      this.accountService.subscriptionKey +
      '&RefNo=' +
      refNo +
      '&Status=Open&PageSize=1&clientFriendlyName=JvRcaTkbYXlirKZj1wgRaQ==';
    return this.http.get<pagedVClaim>(url).toPromise();
  }
}
