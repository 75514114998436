import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActionRequiredForSA, AppId, LargeLossApproval } from '@app/_helpers/constants';
import { Employee } from '@app/_models/employee';
import { JobFile } from '@app/_models/job-file';
import { JobHistoryView } from '@app/_models/job-history-view';
import { AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';
import { Observable, catchError, forkJoin, map, switchMap } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoices-awaiting-approval',
  templateUrl: './invoices-awaiting-approval.component.html',
  styleUrls: ['./invoices-awaiting-approval.component.scss'],
})
export class InvoicesAwaitingApprovalComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'refNo',
    'invoiceNumber',
    'invoiceAmount',
    'tradingName',
    'instructionTypeId',
    'date',
    'assessor',
    'addedBy',
    'timeStamp',
    'actionRequired',
    'details',
    'viewDocument',
  ];

  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  isLoading = true;
  // searchValue = localStorage.getItem('searchTextInvoicesAwaitingApproval')
  //   ? localStorage.getItem('searchTextInvoicesAwaitingApproval')
  //   : '';

  searchValue = '';

  constructor(
    public restService: RestService,
    private alertService: AlertService,
    public functionsService: FunctionsService
  ) {
    this.getRemoteData();
  }

  ngOnInit(): void {}

  getRemoteData() {
    try {
      this.isLoading = true;
      this.getInvoicesAwaitingApproval();
      return true;
    } catch (error: any) {
      this.isLoading = false;
      this.alertService.error(error);
      return false;
    }
  }

  getInvoicesAwaitingApproval() {
    return this.restService
      .getInvoicesAwaitingApproval()
      .subscribe((jobHistory: JobHistoryView[]) => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(jobHistory);
        this.dataSource.sort = this.matSort;

        const ids = jobHistory.map((item) => item.refNo);

        // Use forkJoin to fetch additional data for each ID asynchronously
        const observables = ids.map((id) =>
          this.restService.getClaimOwnerDetails(id!)
        );

        forkJoin(observables).subscribe((assessors) => {
          // Update the rows with the additionalField data
          assessors.forEach((claimOwnerDetails, index) => {
            this.dataSource.data[index].assessor = claimOwnerDetails.consultant;
            if (this.dataSource.data[index].statusId == 19) {
              this.restService
                .getADUserManagerDetails(claimOwnerDetails.consultantID)
                .subscribe((userDetails) => {
                  if (userDetails[0].reviewerId != null && userDetails[0].appId == AppId) {
                    this.restService
                      .getEmployeeDetailsByEmail(userDetails[0].reviewerEmail)
                      .subscribe({
                        next: (approver: Employee) => {
                          this.dataSource.data[index].actionRequired =
                            approver.employeeName;
                            this.dataSource._updateChangeSubscription();
                        },
                        error: (err) => {
                          Swal.fire(
                            'Error: restService.getEmployeeDetailsByEmail in getInvoicesAwaitingApproval<br/>' +
                              err,
                            '',
                            'error'
                          );
                        },
                      });
                  } else if (userDetails[0].workStateId == 6) {
                    //SA
                    this.dataSource.data[index].actionRequired = ActionRequiredForSA;
                      this.dataSource._updateChangeSubscription();
                  } else
                      this.getStateManager(claimOwnerDetails.consultantID).subscribe(data =>{
                        this.dataSource.data[index].actionRequired =data;
                        this.dataSource._updateChangeSubscription();
                      });
                      
                });
            }
            else if (this.dataSource.data[index].statusId == 20) {
              this.dataSource.data[index].actionRequired = LargeLossApproval;
            }
            else{
              this.dataSource.data[index].actionRequired = this.dataSource.data[index].assessor;
            }
          });

          // After updating the data, you may need to refresh the MatTable
          //this.dataSource._updateChangeSubscription();
        }),
          (error: any) => {
            this.isLoading = false;
            //this.alertService.error(error);
            Swal.fire(
              'Error: restService.getInvoicesAwaitingApproval (forkJoin(observables).subscribe)<br/>' +
                error,
              '',
              'error'
            );
          };
      });
  }

  getStateManager(employeeId: number): Observable<string> {
    return this.restService.GetStateManagerForEmployee(employeeId).pipe(
      map((data: Employee) => {
        return data ? data.employeeName : 'State/Branch Manager';
      }),
      catchError((error) => {
        this.alertService.error(error);
        return new Observable<string>(); // Return an empty observable or handle the error differently.
      })
    );
  }

  /*getStateManager(employeeEmail: string): string {
    this.restService.getEmployeeDetailsByEmail(employeeEmail).subscribe(
      (data) => {
        this.restService.getStateManagerDetails(data.workStateId!).subscribe(
          (data) => {
            return data.employeeName;
          });
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }*/

  /*getStateManager(employeeEmail: string): Observable<string> {
    return this.restService.getEmployeeDetailsByEmail(employeeEmail).pipe(
      switchMap((assessor: Employee) => {
        return this.restService.getStateManagerDetails(assessor.workStateId!);
      }),
      map((stateManager: Employee) => 
      stateManager.employeeName),
      catchError((error) => {
        this.alertService.error(error);
        return new Observable<string>(); // Return an empty observable or handle the error differently.
      })
    );
  }*/

  openInvoicePDF(id: number) {
    this.restService.getInvoicePDF(id).subscribe(
      (data: JobFile) => {
        this.functionsService.openPdf(data.fileData);
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

    //Search by keyword
  onSearchKeyUp(event: Event) {
      try {
        var currentFilter = (event.target as HTMLInputElement).value;
        this.dataSource.filter = currentFilter;
        //localStorage.setItem('searchTextInvoicesAwaitingApproval', currentFilter);
        
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
      } catch (error) {
        Swal.fire('Error in Search by keyword : ' + error, '', 'error');
      }
    }
  
    //Clear search by keyword
  onClearClicked(search: { value: string }) {
      this.dataSource.filter = '';
      search.value = '';
      this.searchValue = '';
      //localStorage.setItem('searchTextInvoicesAwaitingApproval', '');
  
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
}
